<template>
  <BaseCard
    title="datos del pedido"
    :loading="loading"
    @save-button-click="handleSaveButtonClick"
  >
    <OrderForm
      ref="order-form"
      v-model="order"
    />
  </BaseCard>
</template>

<script>
import OrdersApi from '@/api/orders-api'
import BaseCard from '@/components/ui/card/BaseCard.vue'
import OrderForm from '@/components/orders/form/OrderForm.vue'

export default {
  components: { OrderForm, BaseCard },
  data() {
    return {
      loading: false,
      order: {},
    }
  },
  methods: {
    async handleSaveButtonClick() {
      const valid = await this.$refs['order-form'].validateForm()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        const { documentation } = this.$refs['order-form'].getDocumentationData()
        const response = await OrdersApi.create({ ...this.order, documentation })
        const newOrder = response.data
        await this.$router.replace({ name: 'viewOrder', params: { id: newOrder.id } })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
</style>
